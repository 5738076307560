<template>
  <PageWithLayout>
    <div class="inner_section">
      <BoardList
        title="공지&amp;이벤트"
        :dataList="viewModel.NoticeDataList"
        :paginationData="viewModel.paginationData"
        itemRouteName="NoticeDetail"
        @onClickBtn="viewModel.getNoticeList()"/>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import BoardList from '@/components/common/board/BoardList';

// viewModel
import NoticeListViewModel from '@/views/blog/viewModel/notice/NoticeListViewModel';

export default {
  name:'NoticeList',
  components:{
    PageWithLayout,
    BoardList
  },
  mounted(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new NoticeListViewModel(),
    }
  },
  metaInfo: {
    meta: [
      {
        property: "description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"og:description",
      },
      {
        property: "twitter:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>
.inner_section{padding:160px 20px 140px}

/* 모바일 */
@media all and (max-width:1199px){
  .inner_section{padding:102px 20px 128px}
}
</style>