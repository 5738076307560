import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import { historyData } from '@/constants/historyData'

export default class NoticeListViewModel {
  constructor() {
    this.isMobile = false;
    this.NoticeDataList = [];
    this.searchParams = {
      nextId:-1,
      limit:9,
    };
    this.paginationData = {
      eof:true
    };
  }
  init(){
    if(historyData.NoticeList.dataList.length > 0){
      this.getHistoryData();
    }else{
      this.getNoticeList();
    }
  }
  getNoticeList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.NOTICE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.NoticeDataList = this.NoticeDataList.concat(resultData.data);
      this.paginationData.eof = resultData.eof;
      this.searchParams.nextId = resultData.next_id;
      this.setHistoryData()
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getHistoryData(){
    this.NoticeDataList = historyData.NoticeList.dataList;
    this.searchParams = historyData.NoticeList.searchParams;
    this.paginationData = historyData.NoticeList.paginationData;
  }
  setHistoryData(){
    historyData.NoticeList.dataList = this.NoticeDataList;
    historyData.NoticeList.searchParams = this.searchParams;
    historyData.NoticeList.paginationData = this.paginationData;
  }
}