const historyData = {
  NoticeList: {
    dataList:[],
    searchParams:{
      page:0,
      pageSize:9,
      direction: 'DESC'
    },
    paginationData:{
      totalPage:0,
      totalCount:0,
      page:0,
    }
  },
  PostList: {
    dataList:[],
    searchParams:{
      page:0,
      pageSize:9,
      direction: 'DESC'
    },
    paginationData:{
      totalPage:0,
      totalCount:0,
      page:0,
    }
  },
}

export { historyData }