<template>
  <div class="share_comm" :style="cssProps">
    <button
      class="btn_share btn_share_kakaotalk"
      @click="onClickShareSns('kakaotalk')">
      <IconSvg
        iconName="kakaotalk"
        iconColor="#BEA9ED"
        bgcolor="#F3F1F1"
        :size="36"/>
      <span class="screen_out">kakaotalk 공유하기</span>
    </button>
    <button
      class="btn_share btn_share_facebook"
      @click="onClickShareSns('facebook')">
      <IconSvg
        iconName="facebook"
        iconColor="#BEA9ED"
        bgcolor="#F3F1F1"
        :size="36"/>
      <span class="screen_out">facebook 공유하기</span>
    </button>
    <button
      class="btn_share btn_share_twitter"
      @click="onClickShareSns('twitter')">
      <IconSvg
        iconName="twitter"
        iconColor="#BEA9ED"
        bgcolor="#F3F1F1"
        :size="36"/>
      <span class="screen_out">twitter 공유하기</span>
    </button>
    <button
      class="btn_share btn_share_link"
      @click="onClickCopy()">
      <IconSvg
        iconName="link"
        iconColor="#BEA9ED"
        bgcolor="#F3F1F1"
        :size="36"/>
      <span class="screen_out">URL 공유하기</span>
    </button>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg.vue';
import { camelToSnake } from '@/utils/stringUtils.js';
export default {
  name:'Share',
  props:{
    metaData: {
      type: Object,
      default: ()=> {
        return{
          title:'퍼핀',
          contents:'용돈은 퍼핀으로 주세요',
          share_thumbnail:'https://www.firfin.family/assets/images/og_banner.jpg',
        }
      }
    },
    itemRouteName : String,
    id : [String,Number],
    tidy : Number,
  },
  data(){
    return{
      snsSharePopupSize:{
        w : 600,
        h : 400
      }
    }
  },
  components:{
    IconSvg,
  },
  computed:{
    cssProps(){
      let rObj = {
        '--tidy' : `${this.tidy || 0}px` 
      };
      return rObj
    },
    link(){
      const isProductionMode = process.env.NODE_ENV === 'production';
      const domain = isProductionMode ? 'https://www.firfin.family' : 'https://www.preprod.firfin.family'
      const path = this.$routerPath[camelToSnake(this.itemRouteName).toUpperCase()]
      const param = this.id;
      return `${domain}${path.replace(':id',param)}`
    }
  },
  methods:{
    onClickShareSns(type){
      switch(type){
        case 'kakaotalk' : {
          this.shareKakaotalk(); break
        }
        case 'facebook' : {
          this.shareFacebook(); break
        }
        case 'twitter' : {
          this.shareTwitter(); break
        }
      }
    },
    shareKakaotalk(){
      // console.log(this.$config.kakaoJSKey,this.metaData)
      try {
        Kakao.init(this.$config.kakaoJSKey);
      } catch (e) {}
      const isProductionMode = process.env.NODE_ENV === 'production';
      const domain = isProductionMode ? 'https://www.firfin.family' : 'https://www.preprod.firfin.family'

      var kakaoLinkData = {
        objectType: 'feed',
        content: {
          title: this.metaData.title.slice(0,50),
          description: this.metaData.contents.replace(/<[^>]*>?/g, '').slice(0,130),
          imageUrl: this.metaData.share_thumbnail,
          link: {
            mobileWebUrl: domain,
            webUrl: domain,
          },
        },
        buttons: [
          {
            title: `퍼핀 ${this.itemRouteName === 'NoticeDetail' ? '공지&이벤트':'포스트'} 보러가기`,
            link: {
              mobileWebUrl: this.link,
              webUrl: this.link,
            },
          },
        ],
      };
      Kakao.Share.sendDefault(kakaoLinkData);
    },
    shareFacebook() {
      const url = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(this.link)}`;
      window.open(url,'_blank', 'width=' + this.snsSharePopupSize.w + ', height=' + this.snsSharePopupSize.h + ',resizable=yes,scrollbars=yes');
      window.open();
    },
    shareTwitter() {
      const url = `https://twitter.com/intent/tweet?url=${this.link}`;
      window.open(url,'_blank', 'width=' + this.snsSharePopupSize.w + ', height=' + this.snsSharePopupSize.h + ',resizable=yes,scrollbars=yes');
    },
    onClickCopy(){
      window.navigator.clipboard.writeText(this.link).then(() => {
        this.$store.dispatch('commonToast/fetchToastStart', {
          toastType : 'success',
          toastMsg: `링크가 복사되었습니다`
        });
      });
    }
  },
}
</script>
<style scoped>
.share_comm .btn_share .icon_firfin{border-radius:100%}
.share_comm .btn_share + .btn_share{margin-left:var(--tidy)}
</style>