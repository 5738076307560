<template>
  <div class="board_comm">
    <h2 class="tit_board">{{ title }}</h2>
    <div class="list_board">
      <BoardItem
        v-for="(item,index) in dataList"
        :key="`ìtem_board_${index}`"
        :itemData="item"
        :itemRouteName="itemRouteName" />
    </div>
    <button
      v-if="routeName || ( paginationData && !paginationData.eof )"
      class="btn_board"
      @click="onClickBtn">{{ btnText || '더보기' }}</button>
  </div>
</template>

<script>
import BoardItem from '@/components/common/board/BoardItem';

export default {
  name:'BoardList',
  props:{
    title: String,
    dataList: Array,
    paginationData:Object, // totalPage, page
    btnText: String,
    routeName: String,
    routeParams: Object,
    routeQuery: Object,
    itemRouteName: String,
  },
  components:{
    BoardItem
  },
  methods:{
    onClickBtn(e){
      if( this.routeName ){
        let routeInfo = { name: this.routeName };
        if(this.routeParams){
          routeInfo.params = this.routeParams;
        }
        this.$router.push(routeInfo);
        return;
      }else{
        this.$emit('onClickBtn',e);
      }
    },
  },
}
</script>
<style scoped>
.tit_board{display:block;font-weight:700;font-size:28px;line-height:36px;color:#111}
.list_board{min-height:472px;margin-top:48px}
.btn_board{display:block;width:180px;margin:48px auto 0;padding:14px 0;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#BEA9ED;text-align:center}

/* 모바일 */
@media all and (max-width:1199px){
  .btn_board{margin-top:32p}
}

/* 모바일 */
@media all and (max-width:880px){
  .board_comm >>> .item_board{display:inline-block;position:relative;width:calc(50% - 20px);max-width:none};
  .board_comm >>> .item_board:nth-child(n+4){margin-top:0px }
  .board_comm >>> .item_board:nth-child(3n+2){margin-left:0px }
  .board_comm >>> .item_board:nth-child(3n+3){margin-left:0px }

  .board_comm >>> .item_board:nth-child(2n+2){margin-left:40px}
  .board_comm >>> .item_board:nth-child(2n+2){margin-left:40px}
  .board_comm >>> .item_board:nth-child(n+3){margin-top:48px}
}
/* 모바일 */
@media all and (max-width:500px){
  .board_comm >>> .item_board{display:inline-block;position:relative;width:100%}
  .board_comm >>> .item_board:nth-child(2n+2){margin-left:0}
  .board_comm >>> .item_board:nth-child(2n+2){margin-left:0}
  .board_comm >>> .item_board:nth-child(n+3){margin-top:0}
  .board_comm >>> .item_board + .item_board{margin-top:32px}
}
</style>